.parcelService,
.parcelTableContainer {
  --sb-track-color: #dfdfdf;
  --sb-thumb-color: #b8b8b8;
  --sb-size: 6px;
}

.parcelService::-webkit-scrollbar,
.parcelTableContainer::-webkit-scrollbar {
  width: var(--sb-size);
}

.parcelService::-webkit-scrollbar-track,
.parcelTableContainer::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

.parcelService::-webkit-scrollbar-thumb,
.parcelTableContainer::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}
