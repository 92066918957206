.productSelectionWrapper{
  background-image: linear-gradient(to bottom, white, rgba(177, 233, 117, 0.5));
  height: 100vh;
  z-index: 2;
}

.productSelectionContianer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.productSelectionHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.productsContainer {
  display: flex;
  gap: 30px;
}

.mainProduct {
  border-radius: 7px;
  padding: 15px;
  height: 130px;
  width: 130px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  transition: background-color 0.4s ease-in-out;
  &:hover {
    background-image: linear-gradient(to bottom, rgb(236, 255, 226), rgb(213, 250, 174));
  }
}

.closeButton {
  display: flex;
  justify-content: end;
  cursor: pointer;
  margin: 15px 15px 0 0;

}
