.authContainer {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #8fe445;
  padding: 20px 30px;
  border-radius: 16px;
  box-shadow: -8px 8px 0px -3px #81d931;
  width: 320px;
  z-index: 10;
}

.turingLogo{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  bottom: -4%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.turingLogo img {
  width: 10vw;
  max-width: 10vw;
}
.createOne {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.createOne > span {
  cursor: pointer;
  margin-left: 5px;
}

.patternLeft, .patternRight{
    position: absolute;
    /* bottom:; */
}
.patternLeft img, .patternRight img{
    height: 99vh;
}

.patternRight {
    right: 0;
}

.formFields{
  margin-top: 20px;
}