.propertiesContainer {
  margin-top: 8vh;
  padding: 1px 80px 20px 80px;
}

.tableParcelTitle {
  cursor: pointer;
  :hover {
    color: grey;
  }
}

.tableActions{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 0;
  justify-content: center;
}

.emptyProperties {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  width: 320px;
  z-index: 10;
}

.folderContainer{
  border: 1px solid rgb(228, 228, 228);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}
.folderContainerPlans{
  border: 1px solid rgb(228, 228, 228);
  padding: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

.folderDataContainer{
  display: flex;
  justify-content: space-between;
}
.folderDataContainerLeft{
  display: flex;
  align-items: center;
}

.folderListContainer{
  height: 165px;
  overflow-y: auto;
  padding: 0 5px;
}

.parcelTableContainer{
  width: 100%;
  margin-bottom: 20px;
}

.folderListContainer, .parcelTableContainer {
  --sb-track-color: #dfdfdf;
  --sb-thumb-color: #b8b8b8;
  --sb-size: 6px;
}

.folderListContainer::-webkit-scrollbar , .parcelTableContainer::-webkit-scrollbar {
  width: var(--sb-size);
}

.folderListContainer::-webkit-scrollbar-track, .parcelTableContainer::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

.folderListContainer::-webkit-scrollbar-thumb, .parcelTableContainer::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

.gif-loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40vh;
}

@media (max-width: 768px) {
  .propertiesContainer {
    margin-top: 8vh;
    padding: 1px 20px 20px 20px;
  }
}
