.headerContainer {
  display: flex;
  padding: 0 40px;
  align-items: center;
  box-shadow: rgb(189 189 189 / 15%) 0px 48px 100px 0px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8vh;
  z-index: 100;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logoAbove {
  line-height: 30px;
  font-size: 24px;
}

.logoBelow {
  color: white;
  font-size: 12px;
}

.logoBelowBold{
    font-weight: 700;
}

.menuContainer h3 {
  cursor: pointer;
  color: white;
}


@media (max-width: 768px) {
  .headerContainer{
    padding: 0 20px;
  }
  .logoAbove {
      font-size: 16px;
  }
  .logoBelow{
    font-size: 8px;
  }
  .menuContainer {
    gap: 20px;
  }
  .menuContainer h3 {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .headerContainer{
    padding: 0 20px;
  }
  .logoAbove {
      font-size: 14px;
      line-height: 20px;
  }
  .logoBelow{
    font-size: 8px;
    display: none;
  }
  .menuContainer {
    gap: 10px;
  }
  .menuContainer h3 {
    font-size: 12px;
  }
}