.devicesPopupContainer {
  position: fixed;  
  bottom: 3%;        
  right: 1%;         
  width: 450px;      
  max-width: 100%;   
  height: 340px;     
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto; 
}

.devicesPopupHeader, .devicesPopupHeaderRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; 
}

.devicesPopupHeaderRight{
  gap: 10px;
  justify-content: flex-start;
}

.inActiveDevice,
.activeDevice {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.inActiveDevice {
  background-color: grey;
}

.activeDevice {
  background: green;
}

.vestTable {
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    background-color: rgba(192, 192, 192, 0.2);
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(87, 87, 87, 0.2);
  }
}
.MuiTableCell-root {
  font-size: 13px;
}

@media (max-width: 768px) {
  .devicesPopupContainer {
    width: 85%;
    height: auto;
    top: 5%;
    right: 5%;
  }
  .devicesPopupHeaderRight {
    gap: 5px;
  }
  .vestTable {
    width: 100%;
  }
  .MuiTableCell-root {
    font-size: 12px; 
  }
  .MuiTableRow-root {
    padding: 8px 0;
  }
  .MuiTableCell-root > div {
    font-size: 12px; 
  }
}

@media (max-width: 480px) {
  .devicesPopupContainer {
    width: 85%;
    height: auto;
    top: 60%;
  }
  .devicesPopupHeader {
    font-size: 14px;
  }
  .devicesPopupHeaderRight {
    gap: 5px;
  }
  .vestTable {
    padding: 0 5px;
  }
  .MuiTableCell-root {
    font-size: 11px;
  }
  .MuiTableRow-root {
    padding: 6px 0;
  }
  .MuiTableCell-root > div {
    font-size: 11px;
  }
}