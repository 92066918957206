.layerToggleButton {
  position: absolute;
  transform: translateX(0%);
  border-radius: 10px;
  gap: 5px;
  padding: 0px 8px;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: white;
}

.propertiesCardLeft .propertiesCardRight{
  /* overflow: auto; */
  border: 1px solid rgb(228, 228, 228);
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.propertySummary {
  &::-webkit-scrollbar {
    background-color: rgba(192, 192, 192, 0.2);
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(87, 87, 87, 0.2);
  }
}

.tooltipContainer{
  display: flex;
  justify-content: space-between;
}