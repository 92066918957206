.loaderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.7); /* Slightly transparent white */
    z-index: 10; /* Ensure it stays above the map */
  }