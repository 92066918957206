.mapPanelContainer {
  height: 92vh;
  margin-top: 8vh;
}

.mapboxgl-ctrl {
  display: flex;
}

.resizeIcon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}

.panelResizeHandle {
  height: 4px;
  background-color: rgb(197, 197, 197);
  :hover {
    background-color: rgb(119, 119, 119);
  }
}

.uploadButtonContainer {
  width: 100%;
}

.drawerWrapper {
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.mainContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.mapControlsTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mapboxMapContainer {
  height: 100%;
  width: 100%;
}

.mapScreenBelowContainer {
  height: 100%;
  overflow: auto;
}

.accordianCrewWorkerReadOnly {
  margin: 0 20px;
}

.mapFilters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.mapFilters label,
.mapStylesContainer label {
  font-size: 14px;
}

.animationControlsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 200px;
  height: 100px;
}

.animationControls > span {
  line-height: 0;
}

.animationControls {
  display: flex;
  gap: 20px;
}

.RightControls {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 88%;
}

.mapContainer {
  height: 100%;
}

.plannerDetailsOpener {
  height: 30px;
  width: 250px;
  background: white;
  color: rgb(87, 87, 87);
  text-align: center;
  border-radius: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 11vh;
  left: 3vh;
  padding: 5px 20px;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.hamburgerMenu {
  min-width: 10%;
}

.siteNameOnTop {
  margin-left: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  gap: 20px;
  justify-content: end;
}

.checkboxContainer div {
  display: flex;
  gap: 10px;
  min-width: 100px;
  flex-direction: column;
}

.graphContainer {
  width: 100%;
  .zoomButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 140px;
  }
}

.panelClass {
  display: flex;
  flex-direction: column;
}

.viewTimelineButton,
.viewMapControls,
.daySelectContainer {
  transform: translateX(-50%);
  background-color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  text-align: center;
  padding: 8px 17px;
  z-index: 1;
}

.viewTimelineButton {
  position: absolute;
  bottom: 30px;
  left: 50%;
  font-size: 14px;
  border-radius: 10px;
}
.viewMapControls {
  position: absolute;
  top: 10vh;
  right: 3vh;
  font-size: 14px;
  transform: translateX(0%);
  border-radius: 10px;
}

.geoFileUpload {
  position: absolute;
  top: 10vh;
  right: 1vh;
  font-size: 12px;
  transform: translateX(0%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.connectingLineUpload {
  position: absolute;
  top: 10vh;
  right: 30vh;
  font-size: 12px;
  transform: translateX(0%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mapControlsForLayers, .mapControlsForCrew {
  position: absolute;
  top: 10vh;
  font-size: 12px;
  transform: translateX(0%);
  background-color: white;
  border: none;
  max-height: 40vh;
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);

  padding: 8px 17px;
  z-index: 1;
}

.mapControlsForLayers{
  right: 1vh;
  width: 22vh;
}

.mapControlsForCrew{
  right: 30vh;
  width: 25vh;

}

.layerCheckboxContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.layerCheckboxLeft{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  /* height: 28px; */
  text-align: center;
}

.changeTimelineSection {
  position: fixed;
  display: flex;
  background-color: white;
  border: none;
  cursor: pointer;
  height: 34px;
  padding: 10px !important;
  border-radius: 4px 4px 0 0;
  transition: left 0.3s ease;
  padding: 0;
  z-index: 10;
}

.tabChange {
  margin-right: 10px;
  border-right: 1px solid rgb(182, 182, 182);
}

.tabSelect {
  height: 37px;
  width: 130px !important;
  font-size: 14px !important;
  margin-right: 10px;
}

.daySelect {
  height: 37px !important;
  width: 100px !important;
}

.tabChange .MuiTabs-root {
  min-height: 37px;
}
.tabChange .MuiButtonBase-root {
  padding: 0 10px;
  min-height: 37px;
}

/* .vis-left.vis-panel.vis-vertical-scroll {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0px;
  }
}

.vis-left.vis-panel.vis-vertical-scroll:hover {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2)
  }
} */

.daySelectContainer .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.controlsTitle {
  font-size: 18px;
  color: #21452e;
}

.closeTimelineButton {
  position: fixed;
  font-size: 24px;
  display: flex;
  align-items: center;
  right: 1%;
  border-radius: 5px 5px 0 0;
  padding: 4px 10px;
  z-index: 1;
  box-shadow: 0 0px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px -9px rgba(60, 64, 67, 0.15);
  background-color: white;
  border: none;
  cursor: pointer;
  transition: bottom 0.3s ease;
}

.viewPlannerDataButton {
  position: absolute;
  top: 50%;
  border-radius: 0 8px 8px 0;
  background-color: white;
  border: none;
  cursor: pointer;
  color: rgb(82, 82, 82);
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 14px 0px;
  z-index: 10;
}

.mapFilters .MuiCheckbox-sizeSmall,
.checkboxContainer .MuiCheckbox-sizeSmall {
  padding: 0px;
}

.viewPlannerDataButton.active {
  animation: slideInFromLeft 0.2s forwards;
}

.changeTimelineSection button {
  font-size: 14px;
}

@keyframes slideInFromLeft {
  0% {
    left: -50px;
  }
  100% {
    left: 400px;
  }
}

@media (max-width: 768px) {
  .plannerDetailsOpener {
    width: auto;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .siteNameOnTop span {
    text-overflow: ellipsis;
  }
}
@media (max-width: 500px) {
  .changeTimelineSection {
    height: 37px;
    padding: 5px !important;
    transform: translateX(0%);
  }
  .changeTimelineSection button {
    font-size: 12px;
    padding: 12px 4px;
    min-width: 70px;
  }
  .daySelectContainer {
    right: 0;
    margin-right: 16px;
  }
  .daySelect {
    font-size: 14px !important;
  }
  .daySelectContainer div {
    font-size: 12px;
  }

  .viewMapControls {
    position: absolute;
    top: 10vh;
    right: 1vh;
    font-size: 12px;
    transform: translateX(0%);
    border-radius: 10px;
    gap: 5px;
    padding: 8px 8px;
  }

  .plannerDetailsOpener {
    height: 26px;
    background: white;
    color: rgb(87, 87, 87);
    text-align: center;
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    top: 10vh;
    left: 1vh;
    padding: 5px 10px;
  }
  .viewTimelineButton {
    transform: translateX(0%);
    bottom: 40px;
    left: 2vw;
  }
}
@media (max-width: 500px) {
  .tabSelect {
    margin-right: 5px;
    padding-left: -15px;
  }
  .tabSelect .MuiSelect-select {
    padding-left: px;
  }

  .daySelect {
    font-size: 14px !important;
  }
}